import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs } from '@mui/material';
import { NavLink, Params, useMatches } from 'react-router-dom';

type MatchWithCrumb = {
  id: string;
  pathname: string;
  params: Params<string>;
  data: unknown;
  handle: {
    crumb: (data: unknown) => JSX.Element;
  };
};

export const BreadCrumb = ({ to, label }: { to?: string; label: string }) =>
  to ? (
    <NavLink
      className={({ isActive }) =>
        isActive ? 'text-sm text-secondaryBlack' : 'text-sm text-secondaryGrey'
      }
      to={to}
      end
    >
      {label}
    </NavLink>
  ) : (
    <span className="text-sm text-secondaryBlack">{label}</span>
  );

export default function Navigation() {
  const matches = useMatches();

  const crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => (match as MatchWithCrumb).handle?.crumb)
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match, m: number) => ({
      ...(match as MatchWithCrumb).handle.crumb(match.data),
      key: m,
    }));

  return (
    <div
      className="px-2 md:px-8 py-3 border-b-grey4 border-solid border-b"
      hidden={location.pathname === '/'}
    >
      <Breadcrumbs
        separator={
          <FontAwesomeIcon
            className="text-xxs pt-0.5"
            icon={['fas', 'chevron-right']}
          />
        }
        aria-label="breadcrumb"
      >
        {crumbs}
      </Breadcrumbs>
    </div>
  );
}
