import { add, format, isFuture, isToday, startOfWeek } from 'date-fns';
import { HTTPError } from 'ky';
import qs from 'qs';
import { Suspense, useEffect } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import {
  ActionFunctionArgs,
  Await,
  Form,
  LoaderFunction,
  defer,
  redirect,
  unstable_useBlocker as useBlocker,
  useFetcher,
  useLoaderData,
  useSearchParams,
  useSubmit,
} from 'react-router-dom';
import { postApi, queryApi } from '../services/api';
import LoadingSpinner from '../shared/components/LoadingSpinner';
import { SpinnerIcon } from '../shared/components/SpinnerIcon';
import Title from '../shared/components/Title';
import AvailabilityRow from './AvailabilityRow';
import Select from './Select';

export type AvailabilityDay = {
  date: string;
  startTime: string;
  endTime: string;
  sleepOver: boolean;
  activeNight: boolean;
  notAvailable: boolean;
};

const WEEK_STARTING_SEARCH_PARAM = 'starting';
const AVAILABILITY_ENDPOINT = `/availability/my/`;
const WEEK_COMMENCING_DISPLAY_FORMAT = 'd MMMM yyyy';
export const AVAILABILITY_API_DATE_FORMAT = 'yyyy-MM-dd';

export const availabilityLoader = (async ({ request }) => {
  const url = new URL(request.url);
  const startDateParam = url.searchParams.get(WEEK_STARTING_SEARCH_PARAM);
  const startDateMonday = getStartOfWeek(
    startDateParam ? new Date(startDateParam) : new Date()
  );
  const apiResponse = queryApi(
    `${AVAILABILITY_ENDPOINT}?startDate=${format(
      startDateMonday,
      AVAILABILITY_API_DATE_FORMAT
    )}&numberOfWeeks=1`
  ).then((res) => res.json<AvailabilityDay[]>());

  return defer({
    availability: apiResponse,
    startDate: startDateMonday,
  });
}) satisfies LoaderFunction;

export const availabilityAction = async ({ request }: ActionFunctionArgs) => {
  const text = await request.text();
  const formData = qs.parse(text);
  const formRows = Object.values(formData);

  const dailyAvailabilities = formRows
    .map((row) => {
      const availability = row as Record<string, string>;
      return {
        date: availability.date,
        startTime: availability.startTime,
        endTime: availability.endTime,
        sleepOver: availability.sleepOver === 'on',
        activeNight: availability.activeNight === 'on',
        notAvailable: availability.available !== 'on',
      };
    })
    .filter((a) => {
      const date = new Date(a.date);
      return includeDate(date);
    });

  try {
    await postApi(
      `${AVAILABILITY_ENDPOINT}`,
      JSON.stringify(dailyAvailabilities)
    );
  } catch (error) {
    if (error instanceof HTTPError) {
      const response = await error.response.json();
      throw new Response(response.Message, {
        status: error.response.status,
        statusText: response.Message,
      });
    }
    throw error;
  }

  const returnUrl = new URL(request.url);
  returnUrl.searchParams.set('saved', 'true');
  return redirect(returnUrl.href);
};

const MAX_WEEKS = 8;
const getStartOfWeek = (date: Date) =>
  startOfWeek(date, {
    weekStartsOn: 1, // Week starts on Monday
  });
const START_OF_CURRENT_WEEK = getStartOfWeek(new Date());

const formatDateOption = (date: Date) =>
  format(date, WEEK_COMMENCING_DISPLAY_FORMAT);
const options = Array.from({ length: MAX_WEEKS }, (_, i) =>
  formatDateOption(add(START_OF_CURRENT_WEEK, { weeks: i }))
);

export default function Availability() {
  const { availability, startDate } = useLoaderData() as {
    availability: Promise<AvailabilityDay[]>;
    startDate: Date;
  };
  const [searchParams] = useSearchParams();
  const submit = useSubmit();
  const fetcher = useFetcher();
  const { control, register, reset } = useForm({
    shouldUseNativeValidation: true,
    shouldUnregister: true,
  });

  const { isDirty } = useFormState({
    control,
  });

  const selectedWeek = startDate ?? START_OF_CURRENT_WEEK;
  const saved = searchParams.get('saved') === 'true' && !isDirty;
  const busy = fetcher.state === 'submitting' || fetcher.state === 'loading';
  const weekSelectElement = document.getElementById(
    WEEK_STARTING_SEARCH_PARAM
  ) as HTMLInputElement | null;

  useEffect(() => {
    if (weekSelectElement) {
      weekSelectElement.value = formatDateOption(startDate);
    }
    reset({ keepValues: true });
  }, [startDate, reset]);

  useEffect(() => {
    if (fetcher.state === 'loading') {
      // Reset form dirty state when loading new availability,
      // but keep loaded values.
      reset({ keepValues: true });
    }
  }, [fetcher.state, reset]);

  const blocker = useBlocker(isDirty);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const proceed = window.confirm(
        'Are you sure you want to leave this page? Your changes will not be saved.'
      );
      if (proceed) {
        blocker.proceed();
      } else {
        blocker.reset();
        if (weekSelectElement) {
          weekSelectElement.value = formatDateOption(startDate);
        }
      }
    }
  }, [blocker, startDate]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
      if (weekSelectElement) {
        weekSelectElement.value = formatDateOption(startDate);
      }
    }
  }, [blocker, isDirty, startDate]);

  return (
    <div className="flex flex-col items-center">
      <div className="w-full md:w-half">
        <Title title="My Availability" />
        <div className="my-4 text-sm">
          Indicate your availability below by checking/unchecking the boxes
          below, and then click the ‘Save’ button.
        </div>
        <div className="messagebox w-full">
          <div className="min-w-full flex flex-col items-center">
            <div className="flex flex-col items-left">
              <Form id="select-week-form">
                <Select
                  label="Week commencing:"
                  name={WEEK_STARTING_SEARCH_PARAM}
                  defaultValue={formatDateOption(selectedWeek)}
                  options={options}
                  onChange={(event) => {
                    submit(event.currentTarget.form);
                  }}
                />
              </Form>

              <Suspense fallback={<LoadingSpinner />}>
                <Await resolve={availability}>
                  {(resolvedAvailability) => (
                    <>
                      {resolvedAvailability.length > 0 ? (
                        <fetcher.Form
                          key={`${formatDateOption(selectedWeek)}`}
                          id="availability-form"
                          method="post"
                        >
                          <table className="table-auto mt-4">
                            <thead className="text-left text-sm font-bold text-secondaryNavyBlue">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-1 sm:px-2 py-2 pl-0"
                                ></th>
                                <th scope="col" className="px-1 sm:px-2 py-2">
                                  Start
                                </th>
                                <th scope="col" className="px-1 md:px-2 py-2">
                                  End
                                </th>
                                <th scope="col" className="px-1 sm:px-2 py-2">
                                  <abbr title="Sleepover">S/O</abbr>
                                </th>
                                <th
                                  scope="col"
                                  className="px-1 sm:px-2 py-2 pr-0"
                                >
                                  <abbr title="Active Night">A/N</abbr>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {resolvedAvailability.map(
                                (value: AvailabilityDay) => (
                                  <AvailabilityRow
                                    key={value.date}
                                    availability={value}
                                    register={register}
                                    disabled={
                                      !includeDate(new Date(value.date))
                                    }
                                  />
                                )
                              )}
                            </tbody>
                          </table>
                        </fetcher.Form>
                      ) : (
                        <div className="mt-5 text-sm text-red">
                          Could not load availability.
                        </div>
                      )}
                    </>
                  )}
                </Await>
              </Suspense>
            </div>
          </div>
        </div>

        <div className="pt-4 text-right">
          <button
            className="w-35 h-11 text-secondaryBlack bg-secondaryWhite font-normal text-md shadow-button border border-solid border-primaryGreen"
            type="submit"
            form="availability-form"
            aria-disabled={busy}
          >
            <SpinnerIcon
              isLoading={busy}
              staticIcon={saved ? 'check' : 'save'}
              className="mr-4"
            />
            <span>{busy ? 'Saving...' : saved ? 'Saved' : 'Save'}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

const includeDate = (date: Date) => isToday(date) || isFuture(date);
