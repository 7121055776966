import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import TestId from '../TestId';
import getFacilityBadges from '../util/getFacilityBadges';
import ShiftStatusBadge from './ShiftStatusBadge';

import { EntityType } from '../EntityType';
import { Shift } from '../models';
import { Timesheet } from '../models/Timesheet';
import AllowanceBadges, { hasAllowances } from './AllowanceBadges ';
import RequirementBadges, { hasRequirements } from './RequirementBadges';
import SleepoverAwakeTimes from './SleepoverAwakeTimes';

type Props = {
  shiftData: Shift | Timesheet;
  entityType: EntityType;
  isOffer?: boolean;
  showDetails?: boolean;
};

export default function ShiftSummary({
  shiftData,
  entityType,
  isOffer,
  showDetails,
}: Props) {
  const timesheet = shiftData as Timesheet;
  let diffDate = false;
  let diffBreak = false;

  if (entityType === EntityType.Timesheet) {
    diffDate =
      (!!timesheet.actualStartDate || !!timesheet.actualEndDate) &&
      (moment(timesheet.startDate).diff(
        moment(timesheet.actualStartDate),
        'minutes'
      ) !== 0 ||
        moment(timesheet.endDate).diff(
          moment(timesheet.actualEndDate),
          'minutes'
        ) !== 0);

    diffBreak =
      (!!timesheet.actualBreakTimeInMinutes ||
        (!timesheet.breakTimeInMinutes &&
          !!timesheet.actualBreakTimeInMinutes)) &&
      parseInt(timesheet.breakTimeInMinutes?.toString()) !==
        parseInt(timesheet.actualBreakTimeInMinutes?.toString());
  }

  const showRequirements =
    hasRequirements(shiftData) && (showDetails || isOffer);
  const showAllowances = hasAllowances(shiftData) && showDetails;
  const showDistanceFromHome =
    shiftData.distanceFromHomeInKm > 0 && isOffer === true;
  const showShiftSummary = false; // shiftData.shiftSummary && isOffer && showDetails; // this is commented out until ONCALL create a new field in the OCP UI for the Shift Offer 'Summary' text and update the details stored procedure to return it in a field named 'shiftSummary'
  const showNotes = shiftData.notes && !isOffer && showDetails;

  return (
    <div
      className={
        (isOffer ? 'md:min-h-102 xl:min-h-95 ' : 'md:min-h-51 ') +
        'flex flex-col justify-between border p-3 rounded bg-secondaryWhite border-secondaryShadow shadow'
      }
    >
      <div className="grow flex justify-between">
        <div className="grow flex justify-between">
          <div className="grow">
            <div className="flex justify-between">
              <div className="text-sm font-bold">
                <span>SHIFT ID: </span>
                <span data-testid={TestId.ShiftID}>{shiftData.shiftID}</span>
              </div>
            </div>

            <div className="mt-2 text-md uppercase">
              {shiftData.serviceArea}
            </div>

            <div className="text-lg mt-1.5 font-bold">
              <span>
                {shiftData.shiftTitle +
                  (shiftData.shiftTitle ? ', ' : '') +
                  shiftData.address}
              </span>
            </div>

            {shiftData.startDate && (
              <div className="icon-text-group">
                <div>
                  <FontAwesomeIcon icon={['fas', 'calendar-day']} size="sm" />
                  &ensp;
                  <span>
                    {moment(shiftData.startDate).format('ddd DD MMM')}
                  </span>
                </div>

                {(showDetails || timesheet.timeSheetStatus !== 'Submitted') && (
                  <div>
                    <FontAwesomeIcon icon={['fas', 'clock']} size="sm" />
                    &ensp;
                    <span className={diffDate ? 'line-through' : ''}>
                      <span>{moment(shiftData.startDate).format('h:mma')}</span>
                      &nbsp;<span>-</span>&nbsp;
                      <span>{moment(shiftData.endDate).format('h:mma')}</span>
                    </span>
                    {diffDate && (
                      <span className="ml-2 text-red">
                        <span>
                          {moment(timesheet.actualStartDate).format('h:mma')}
                        </span>
                        &nbsp;<span>-</span>&nbsp;
                        <span>
                          {moment(timesheet.actualEndDate).format('h:mma')}
                        </span>
                      </span>
                    )}
                  </div>
                )}

                {parseInt(shiftData.breakTimeInMinutes?.toString()) > 0 ||
                parseInt(timesheet.actualBreakTimeInMinutes?.toString()) > 0 ? (
                  <div>
                    <FontAwesomeIcon icon={['fas', 'mug-hot']} size="sm" />
                    &ensp;
                    <span className={diffBreak ? 'line-through' : ''}>
                      <span>
                        {shiftData.breakTimeInMinutes}&nbsp;mins.&nbsp;break
                      </span>
                    </span>
                    {diffBreak && (
                      <span className="ml-2 text-red">
                        {timesheet.actualBreakTimeInMinutes}
                        &nbsp;mins.&nbsp;break
                      </span>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            {getFacilityBadges(shiftData)}

            {(timesheet.actualSleepoverActiveHours ||
              timesheet.sleepoverActiveHours) && (
              <SleepoverAwakeTimes
                startDate={timesheet.startDate}
                endDate={timesheet.endDate}
                awakeTimes={
                  timesheet.actualSleepoverActiveHours ??
                  timesheet.sleepoverActiveHours
                }
              />
            )}

            {(showRequirements || showAllowances || showDistanceFromHome) && (
              <div>
                <hr className="mt-5 mb-4 text-grey3" />

                {showRequirements && <RequirementBadges shift={shiftData} />}

                {showAllowances && <AllowanceBadges shift={shiftData} />}

                {showDistanceFromHome && (
                  <>
                    <div className="mt-4 font-bold">Distance:</div>
                    <span>
                      <FontAwesomeIcon
                        icon={['fas', 'diamond-turn-right']}
                        className="text-sm w-4"
                      />
                      &ensp;
                      {shiftData.distanceFromHomeInKm}km from home (approx.)
                    </span>
                  </>
                )}
              </div>
            )}

            {showShiftSummary && (
              <div>
                <hr className="mt-5 mb-4 text-grey3" />
                <div className="mt-4 font-bold">Shift Summary:</div>
                <div>{shiftData.shiftSummary}</div>
              </div>
            )}

            {showNotes && (
              <div>
                <hr className="mt-5 mb-4 text-grey3" />
                <div className="mt-4 font-bold">Information:</div>
                <div>{shiftData.notes}</div>
              </div>
            )}

            {timesheet.reason && (
              <div>
                <hr className="mt-5 mb-4 text-grey3" />
                <div className="font-bold mt-4">Reason:</div>
                {timesheet.reason}
              </div>
            )}
          </div>

          {!showDetails && (
            <div className="self-center">
              <FontAwesomeIcon
                icon={['fas', 'chevron-right']}
                className="text-sm w-4"
              />
            </div>
          )}
        </div>

        <span className="relative grow-0">
          <span className="absolute top-0 right-0">
            <ShiftStatusBadge
              status={
                entityType === EntityType.Timesheet
                  ? shiftData.timeSheetStatus
                  : shiftData.shiftStatus
              }
            />
          </span>
        </span>
      </div>
    </div>
  );
}
